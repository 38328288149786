<template>
	<div>
		<el-card>
<!--			<el-row :gutter="20" class="left">-->
<!--				<el-col :span="24">-->
<!--					<span style="color:#666">技师列表</span>-->
<!--				</el-col>-->
<!--			</el-row>-->
<!--			<el-divider></el-divider>-->
			<el-row class="left">
				<el-col :span="24">
					<el-button type="primary" size="small" icon="el-icon-plus" @click="addTech">新增服务人员</el-button>
					<el-button :class="data_type==0?'is-active':''" type="default" size="small" plain @click="getAll">全部 ({{allCount}})</el-button>
					<el-button :class="data_type==1?'is-active':''" type="default" size="small" plain @click="getUnauthorized">未授权 ({{unauthorizedCount}})</el-button>
					<el-button :class="data_type==2?'is-active':''" type="default" size="small" plain @click="getAppply">申请中 ({{appplyCount}})</el-button>
					<el-button :class="data_type==3?'is-active':''" type="default" size="small" plain @click="getauthorized">已授权 ({{authorizedCount}})</el-button>
				</el-col>
			</el-row>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true"  class="demo-form-inline">
						<el-form-item label="输入查询">
							<el-input v-model="searchText" placeholder="请输入姓名/手机号" clearable></el-input>
						</el-form-item>
						<el-form-item label="创建时间">
							<el-date-picker
					      v-model="datePicker"
					      type="daterange"
					      range-separator="至"
					      start-placeholder="开始日期"
					      end-placeholder="结束日期" 
					      value-format="yyyy-MM-dd"
					      >
					    </el-date-picker>
						</el-form-item>
            <el-form-item label="服务类型">
              <el-select v-model="queryForm.type_id" placeholder="请选择服务类型" clearable width="100%" style="display: block;">
                <el-option v-for="item in typeList" :key="item.type_id" :label="item.type_name" :value="item.type_id"/>
              </el-select>
            </el-form-item>
            <el-form-item label="入驻状态">
              <el-select v-model="queryForm.entry_status" placeholder="请选择入驻状态" clearable width="100%" style="display: block;">
                <el-option v-for="item in entryStatusList" :key="item.value" :label="item.name" :value="item.value"/>
              </el-select>
            </el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-table border :data="techList" :header-cell-style="{ background: '#eef1f6', color: '#606266' }" height="calc(100vh - 400px)">
				<el-table-column prop="jishi_id" label="ID" width="60px"></el-table-column>
				<el-table-column label="头像">
					<template slot-scope="scope">
						<div>
							<el-image style="width: 60px; height: 60px; border-radius: 7px;" :src="scope.row.gzxxz_img" fit="fit"></el-image>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="jishi_name" label="姓名" width="100px"></el-table-column>
				<el-table-column prop="jishi_sex" label="性别"></el-table-column>
				<el-table-column prop="phone" label="手机号" width="120px"></el-table-column>
				<el-table-column prop="yxgzcs" label="工作城市"></el-table-column>
				<el-table-column label="上岗状态" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.jishi_status" @change="setJishiStatus(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="停牌状态" :show-overflow-tooltip="true" min-width="150px">
          <template slot-scope="scope">
            <div v-for="(v,k) in scope.row.type_list" :key="k" style="margin-top: 10px;">
              <div v-if="v.stop_type && v.stop_type > 0">
                <el-tag type="danger" v-if="v.is_stop && v.stop_type === 1">{{v.type_name}}停牌24小时</el-tag>
                <el-tag type="danger" v-if="v.is_stop && v.stop_type === 2">{{v.type_name}}停牌48小时</el-tag>
                <el-tag type="danger" v-if="v.is_stop && v.stop_type === 3">{{v.type_name}}永久停牌</el-tag>
                <el-tag type="danger" v-if="v.is_stop && v.stop_type === 4">{{v.type_name}}停牌{{v.stop_start}}至{{v.stop_end}}</el-tag>
                <el-tag type="success" v-if="!v.is_stop && v.stop_type === 4">{{v.type_name}}停牌{{v.stop_start}}至{{v.stop_end}}</el-tag>
              </div>
              <div v-else>
                <el-tag type="danger" v-if="v.is_stop">{{v.type_name}}已停牌</el-tag>
              </div>
            </div>
          </template>
        </el-table-column>
				<!--<el-table-column label="申请人">
					<template slot-scope="scope">
						<div>
							<el-tag type="info" v-show="scope.row.data_ly">用户</el-tag>
							<el-tag type="primary" v-show="!scope.row.data_ly">平台</el-tag>
						</div>
					</template>
				</el-table-column>-->
				<el-table-column label="申请状态">
					<template slot-scope="scope">
						<div>
							<el-tag type="success" v-if="scope.row.is_renzheng">已认证</el-tag>
							<el-tag type="danger" v-if="!scope.row.is_renzheng">暂未认证</el-tag>
						</div>
					</template>
				</el-table-column>
				<!--<el-table-column label="状态">
					<template slot-scope="scope">
						<div>
							<el-tag type="success" v-show="scope.row.is_shouquan">已授权</el-tag>
							<el-tag type="danger" v-show="!scope.row.is_shouquan">取消授权</el-tag>
						</div>
					</template>
				</el-table-column>-->
				<el-table-column prop="menpai_name" label="门派" width="100px"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="服务类型" width="260px">
          <template slot-scope="scope">
            <el-tag type="primary" disable-transitions v-for="(item,index) in scope.row.type_list" :key="index" style="margin-left: 5px;">{{item.type_name}}-{{item.career}}&nbsp;&nbsp;</el-tag>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="入驻状态">
          <template slot-scope="scope">
            <el-tag type="success" disable-transitions v-if="scope.row.is_entry">已入驻</el-tag>
            <el-tag type="danger" disable-transitions v-else>未入驻</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="entry_time" label="入驻时间" :show-overflow-tooltip="true" width="150"></el-table-column>
				<el-table-column prop="add_time" label="创建时间" width="150px"></el-table-column>
				<!--<el-table-column prop="agent_name" label="所属代理商"></el-table-column>-->
        <el-table-column label="紧急联系人" width="120">
          <template slot-scope="scope">
            <el-button size="mini" type="success" plain @click="contactView(scope.row)">紧急联系人</el-button>
          </template>
        </el-table-column>
				<el-table-column fixed="right" label="操作" width="290px">
					<template slot-scope="scope">
						<el-row style="margin-bottom:10px">
							<el-button size="mini" type="primary" plain @click="editCurrent(scope.row.jishi_id)">编辑</el-button>
<!--							<el-button size="mini" type="danger" plain @click="removeCurrent(scope.row.jishi_id)">删除</el-button>-->
							<el-button size="mini" type="warning" plain @click="openStop(scope.row)">停牌设置</el-button>
              <el-button size="mini" type="primary" plain @click="relationService(scope.row)">关联服务</el-button>
						</el-row>
						<el-row>

							<el-button size="mini" type="danger" plain @click="changeBz(scope.row)">分佣标准</el-button>
							<el-button size="mini" type="warning" plain @click="changeSect(scope.row)">修改门派</el-button>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 查看对话框 -->
			<el-dialog title="服务人员详情" :visible.sync="checkVisible" top="5vh">
				<el-form ref="checkForm" :model="checkForm" label-width="120px" class="left">
					<el-form-item label="用户ID:"><span>{{checkForm.jishi_id}}</span></el-form-item>
					<el-form-item label="昵称:"><span>{{checkForm.nickName}}</span></el-form-item>
					<el-form-item label="姓名:"><span>{{checkForm.jishi_name}}</span></el-form-item>
					<el-form-item label="性别:"><span>{{checkForm.jishi_sex}}</span></el-form-item>
					<el-form-item label="手机号:"><span>{{checkForm.phone}}</span></el-form-item>
					<el-form-item label="从业年份:"><span>{{checkForm.cynf}}</span></el-form-item>
					<el-form-item label="意向工作城市:"><span>{{checkForm.yxgzcs}}</span></el-form-item>
					<el-form-item label="所在地址:"><span>{{checkForm.address}}</span></el-form-item>
					<el-form-item label="简介:"><span>{{checkForm.jianjie}}</span></el-form-item>
					<el-form-item label="身份证号:"><span>{{checkForm.sfzh}}</span></el-form-item>
					<el-form-item label="身份证正反面:">
						<template>
							<span v-for="(item,index) in checkForm.sfzh_img" :key="index" style="margin-right:10px">
				                <el-image
				                  style="width: 100px; height: 100px"
				                  :src="item"
				                  fit="fit">
				                </el-image>
			              	</span>
						</template>
					</el-form-item>
					<el-form-item label="资格证书:">
						<template>
							<span v-for="(item,index) in checkForm.zgzs_img" :key="index" style="margin-right:10px">
				                <el-image
				                  style="width: 100px; height: 100px"
				                  :src="item"
				                  fit="fit">
				                </el-image>
			             	 </span>
						</template>
					</el-form-item>
					<el-form-item label="工作形象照:">
						<template>
							<span style="margin-right:10px">
				                <el-image
				                  style="width: 100px; height: 100px"
				                  :src="checkForm.gzxxz_img"
				                  fit="fit">
				                </el-image>
			              	</span>
						</template>
					</el-form-item>
					<el-form-item label="个人生活照:">
						<template>
							<span v-for="(item,index) in checkForm.grshz_img" :key="index" style="margin-right:10px">
				                <el-image
				                  style="width: 100px; height: 100px"
				                  :src="item"
				                  fit="fit">
				                </el-image>
			              	</span>
						</template>
					</el-form-item>
				</el-form>
				<el-divider></el-divider>
				<el-row :gutter="10" class="left pad">
					<el-col :span="12">
						<span style="margin-right:20px">审核结果:</span>
						<el-tag type="success" v-show="checkForm.is_shouquan">已授权</el-tag>
						<el-tag type="danger" v-show="!checkForm.is_shouquan">未授权</el-tag>
					</el-col>
					<el-col :span="12">
						<span style="margin-right:20px">审核时间:</span>
						<span style="color:red">{{checkForm.verify_time}}</span>
					</el-col>
				</el-row>
				<el-button type="default" @click="checkVisible=false">取消</el-button>
			</el-dialog>
			<!-- 修改分佣标准对话框 -->
      <el-dialog title="修改分佣标准" width="600px" :visible.sync="bzVisible">
        <el-form label-width="80px">
          <el-tabs v-model="activeName" @tab-click="handleBiaozhunClick" style="margin-bottom: 0px;">
            <el-tab-pane v-for="(item,index) in biaozhunTypeList" :label="item.type_name" :name="'type@'+item.type_id" style="margin-top: 15px;">
              <el-form-item label="分佣标准:">
                <el-select v-model="item.biaozhun_id" placeholder="请选择" style="width: 100%;" clearable @change="changeBiaozhun">
                  <el-option
                      v-for="item in biaozhun_list"
                      :key="item.biaozhun_id"
                      :label="item.biaozhun_name"
                      :value="item.biaozhun_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
          <!-- 发展技师 -->
          <el-row class="fy-row">
            <el-col :span="24" class="info">
              <div class="custom-item">发展技师</div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="技师前" prop="num">
                <el-input type="number" v-model.number="biaozhunShow.num" placeholder="请输入技师次数" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" class="flex-box">
              笔，分成比例
            </el-col>
            <el-col :span="8">
              <el-form-item label="" prop="bfb" label-width="10px">
                <el-input type="number" v-model="biaozhunShow.bfb" placeholder="请输入技师百分比" disabled>
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 发展客户 -->
          <el-row class="fy-row">
            <el-col :span="24" class="info">
              <div class="custom-item">发展客户</div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="客户前" prop="num_1">
                <el-input type="number" v-model.number="biaozhunShow.num_1" placeholder="请输入用户次数" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" class="flex-box">
              笔，分成比例
            </el-col>
            <el-col :span="8">
              <el-form-item label="" prop="bfb_1" label-width="10px">
                <el-input type="number" v-model="biaozhunShow.bfb_1" placeholder="请输入用户百分比" disabled>
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-button type="default" @click="bzVisible=false">取消</el-button>
          <el-button type="primary" @click="subBzEdit">确认</el-button>
        </el-form>
      </el-dialog>

			<!-- 修改门派对话框 -->
			<el-dialog title="修改门派" width="36%" :visible.sync="sectVisible">
				<el-form label-width="80px">
					<el-form-item label="门派:" style="margin-bottom: 20px;">
						<el-select v-model="editSect" placeholder="请选择">
					    <el-option
					      v-for="item in menpai_list"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					  </el-select>
					</el-form-item>
					<el-button type="default" @click="sectVisible=false">取消</el-button>
					<el-button type="primary" @click="subSectEdit">确认</el-button>
				</el-form>
			</el-dialog>
			<!-- 修改代理商对话框 -->
			<el-dialog title="修改代理商" width="36%" :visible.sync="agentVisible">
				<el-form label-width="80px">
					<el-form-item label="代理商:" style="margin-bottom: 20px;">
						<el-select v-model="editAgent" placeholder="请选择">
					    <el-option
					      v-for="item in agent_list"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					  </el-select>
					</el-form-item>
					<el-button type="default" @click="agentVisible=false">取消</el-button>
					<el-button type="primary" @click="subAgentEdit">确认</el-button>
				</el-form>
			</el-dialog>
			<!-- 紧急联系人 -->
			<el-dialog title="紧急联系人" width="40%" :visible.sync="contactVisible">
				<el-table :data="contact_list" border style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				    <el-table-column prop="contact_type" label="关系"></el-table-column>
				    <el-table-column prop="contact_name" label="姓名"></el-table-column>
				    <el-table-column prop="contact_phone" label="电话"></el-table-column>
			  	</el-table>
				<div slot="footer" class="dialog-footer">
				    <el-button @click="contactVisible = false">取 消</el-button>
			  	</div>
			</el-dialog>
			
			<!-- 关联服务 -->
			<el-dialog title="关联服务" :visible.sync="serviceVisible" width="60%" top="5vh">
	        	<el-row :gutter="10" style="margin-bottom: 20px">
	        		<el-col :span="10">
			            <el-input placeholder="请输入服务名称" v-model="serviceText" clearable @clear="getService">
			            </el-input>
	        		</el-col>
	          		<el-col :span="3">
	            		<el-button type="primary" icon="el-icon-search" @click="getService">搜索</el-button>
	          		</el-col>
	        	</el-row>
		        <!-- 表格 -->
		        <el-table ref="xyfwTable" @select="select" @select-all="selectAll" 
		        	:data="serviceTable" style="width: 100%" 
		        	:header-cell-style="{ background: '#eef1f6', color: '#606266' }">
		        	<el-table-column type="selection"></el-table-column>
		        	<el-table-column prop="service_id" label="ID" width="100px"></el-table-column>
		        	<el-table-column label="服务LOGO" width="150px">
			            <template slot-scope="scope">
			            	<div>
				                <el-avatar shape="square" size="large" :src="scope.row.service_logo"></el-avatar>
			              	</div>
			            </template>
	        		</el-table-column>
	        		<el-table-column prop="service_name" label="服务名称"></el-table-column>
	        	</el-table>
	        	<!-- 分页 -->
	        	<Pagination :queryInfo="queryInfoService" :total="serviceTotal" @handleSizeChange="handleSizeChangeService" @handleCurrentChange="handleCurrentChangeService"></Pagination>
	        	<el-button type="default" @click="serviceVisible = false">取消</el-button>
	        	<el-button type="primary" @click="relationSubmit">确认</el-button>
			</el-dialog>

      <!-- 停牌设置 -->
      <el-dialog title="停牌设置" width="600px" :visible.sync="stopDialog" :modal-append-to-body="false">
        <el-form ref="stopForm" :model="stopForm" label-width="120px" :rules="stopRules">
          <el-row>
            <el-col :span="22">
              <el-form-item label="停牌类型" prop="type_id">
                <el-select v-model="stopForm.type_id" placeholder="请选择停牌类型" clearable style="width: 100%;">
                  <el-option v-for="item in stopTypeList" :key="item.type_id" :label="item.type_name" :value="item.type_id" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="停牌原因" prop="stop_reason">
                <el-input v-model="stopForm.stop_reason" placeholder="请输入停牌原因" type="textarea"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="停牌时间:" prop="stop_type">
                <el-select v-model="stopForm.stop_type" placeholder="请选择停牌时间" clearable style="width: 100%;">
                  <el-option v-for="item in stopTimeList" :key="item.value" :label="item.name" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="22" v-if="stopForm.stop_type===4">
              <el-form-item label="申请时间" prop="stop_start">
                <el-date-picker
                    v-model="stopTime"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @change="stopTimeChange"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24" style="text-align:center">
              <el-button type="default" @click="stopDialog=false">取消</el-button>
              <el-button type="primary" @click="stopSetting">确定</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-dialog>

		</el-card>
	</div>
</template>

<script>
	import WxUserSelect from "@/components/Form/WxUserSelect.vue";

  let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
  import Treeselect from "@riophae/vue-treeselect";
  import {setUserStop} from "@/api/request/partner";
  import {biaozhunAll, serviceTypeAll, setUserBiaozhun} from "@/api/request/service";
	export default {
		components: {
      Treeselect, WxUserSelect,
			Pagination
		},
		data() {
			return {
        queryForm: {
          type_id: '',
          entry_status: '',
        },
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				data_type:0,
				allCount: 0,
				unauthorizedCount: 0,
				appplyCount: 0,
				authorizedCount: 0,
				searchText: "",
				datePicker: "",
				total: 0,
				techList: [],
				url: 'https://img1.baidu.com/it/u=3642236042,906489063&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=500',
				checkForm: {},
				checkFormCurrent: {},
				checkVisible: false,
				sectVisible: false,
				agentVisible:false,
				jishi_id: 0,
				//分佣标准
				bzVisible: false,
        biaozhun_id: '',
        activeName: '',
        biaozhunForm: {
          relation_id: '',
          user_type: 3,
          biaozhun: ""
        },
        biaozhun_list: [],
        biaozhunTypeList: [],
        biaozhunShow: {},
				//修改门派
				editSect: '',
				menpai_list:[],
				//修改代理
				editAgent: '',
				agent_list: [],
				contactVisible: false,
				contact_list: [],
				//关联服务
				queryInfoService: {
					pagenum: 1,
					pagesize: 10,
				},
				serviceTotal: 0,
				serviceTable: [],
				serviceVisible: false,
				serviceText: "",
				selectdata: [],
				serviceList: [],
        stopTimeList:[
          {name: "24小时",value: 1},
          {name: "48小时",value: 2},
          {name: "永久停牌",value: 3},
          {name: "日历选择",value: 4},
        ],
        stopForm: {
          stop_user_id: 0,
          user_type: 3,
          stop_reason: '',
          type_id: null,
          stop_type: null,
          stop_start: '',
          stop_end: '',
        },
        stopDialog: false,
        stopTime: "",
        stopRules: {
          type_id: [{required: true, message: "请输入选择停牌类型", trigger: 'change'},],
          stop_reason: [{required: true, message: "请输入停牌原因", trigger: "blur"},],
          stop_type: [{required: true, message: "请选择停牌时间", trigger: 'change'},],
        },
        stopTypeList: [],
        typeList: [],
        entryStatusList:[
          {name: '已入驻',value: 1},
          {name: '未入驻',value: 2}
        ],
			};
		},
		created() {
			that = this;
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				this.getJishiCount();
				this.getJishiList();
				this.getServiceList();
        this.getServiceType();
        this.getBiaozhunList();
			}
		},
		methods: {
      async getServiceType() {
        const res = await serviceTypeAll({})
        if (res.code!==200) this.$message.error(res.msg);
        this.typeList = res.data;
      },
      async getBiaozhunList() {
        const res = await biaozhunAll({user_type: 3})
        console.log(res)
        if (res.status) {
          this.biaozhun_list = res.data;
        } else {
          this.$message.error(res.msg);
        }
      },
			getJishiCount(){
				var url = 'jishi/getJishiCount';
				let params = {};
				this.fd_post(url, params).then((res) => {
					this.allCount = res.allCount;
					this.unauthorizedCount = res.unauthorizedCount;
					this.appplyCount = res.appplyCount;
					this.authorizedCount = res.authorizedCount;
					this.menpai_list = res.menpai_list;
					// this.biaozhun_list = res.biaozhun_list;
//					this.agent_list = res.agent_list;
				}).catch((err) => {
					this.$message.error('网络错误');
				});

        // this.fd_post('index/getDict', {name: "STOP_WORK_TYPE"}).then((res) => {
        //   this.stopTypeList = res.data
        // })
			},
			getJishiList() {
				var url = 'jishi/jishi_list';
				let params = {
					data_type: this.data_type,
					jishi_name: this.searchText,
					date_time: this.datePicker,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
          ...this.queryForm
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.techList = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//新增服务人员
			addTech() {
				this.$router.push('/technician_manage/add')
			},
			// 全部服务人员
			getAll() {
				this.data_type = 0;
				this.getJishiList();
			},
			// 未授权服务人员
			getUnauthorized() {
				this.data_type = 1;
				this.getJishiList();
			},
			// 申请中服务人员
			getAppply() {
				this.data_type = 2;
				this.getJishiList();
			},
			// 已授权服务人员
			getauthorized() {
				this.data_type = 3;
				this.getJishiList();
			},
			// 搜索
			search() {
        this.queryInfo.pagenum = 1;
				this.getJishiList();
			},
			// 重置
			reset() {
        this.queryInfo.pagenum = 1;
				this.searchText = "";
				this.datePicker = "";
        this.queryForm = {
          type_id: '',
          entry_status: ''
        }
				this.getJishiList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getJishiList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getJishiList();
			},
			//查看当前服务人员
			checkCurrent(scope) {
				this.checkForm = scope.row;
				this.checkFormCurrent = scope.row
				console.log(this.checkForm);
				this.checkVisible = true
			},
			editCurrent(id){
				this.$router.push({
					path: '/technician_manage/add',
					query: {
						id
					}
				})
			},
			//设置服务人员上岗状态
			setJishiStatus(item){
				if(timer != null){clearTimeout(timer);}
				timer = setTimeout(() => {
					var url = 'jishi/setJishiStatus';
		        	let params = {
		        		jishi_id: item.jishi_id
		        	};
					that.fd_post(url, params).then((res) => {
		        		if(res.status){
		        			that.$message.success("操作成功");
		        		}else{
		        			that.$message.error(res.msg);
		        		}
			        }).catch((err) => {
			            that.$message.error('网络错误');
			        });
				},300);
			},
			//删除当前服务人员
			removeCurrent(id) {
				this.$confirm('你确认要删除吗?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'jishi/delete_jishi';
			        let params = {
			        	jishi_id: id,
			        };
			        this.fd_post(url, params).then((res) => {
		        		if(res.status){
		        			this.$message.success('删除成功');
		        			this.getJishiList();
		        		}else{
		        			this.$message.error(res.msg);
		        		}
			        }).catch((err) => {
			            this.$message.error('网络错误');
			        });
				}).catch(() => {
					this.$message.info('取消删除')
				});
			},
			//取消授权
			cancelEmpower(i, id) {
				this.$confirm('你确认要取消授权吗?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'jishi/updateVerify';
			        let params = {
			        	jishi_id: id,
			        };
			        this.fd_post(url, params).then((res) => {
		        		if(res.status){
		        			this.techList[i].is_shouquan = !this.techList[i].is_shouquan;
		        		}else{
		        			this.$message.error(res.msg);
		        		}
			        }).catch((err) => {
			            this.$message.error('网络错误');
			        });
				}).catch(() => {
					this.$message.info('取消')
				});
			},
      //分佣标准
      changeBz(item){
        this.biaozhunForm.relation_id = item.jishi_id;
        this.biaozhunForm.biaozhun = "";
        this.activeName = ""
        this.biaozhunTypeList = item.type_list.map(item => {
          return {...item, biaozhun_id: item.biaozhun_id?item.biaozhun_id:""}
        });
        if (this.biaozhunTypeList&&this.biaozhunTypeList.length>0) {
          this.activeName = 'type@'+this.biaozhunTypeList[0].type_id
          if (this.biaozhunTypeList[0].biaozhun_id) {
            this.biaozhunShow = this.biaozhun_list.find(item => item.biaozhun_id === this.biaozhunTypeList[0].biaozhun_id)
          }
        }
        this.bzVisible = true;
      },
      changeBiaozhun(biaozhun_id){
        this.biaozhunShow = this.biaozhun_list.find(item => item.biaozhun_id === biaozhun_id)
      },
      async subBzEdit(){
        const biaozhun = this.biaozhunTypeList.map(item => {
          return {
            type_id: item.type_id,
            biaozhun_id: item.biaozhun_id
          }
        })
        this.biaozhunForm.biaozhun = JSON.stringify(biaozhun)
        console.log("【设置标准】",this.biaozhunForm);
        const res = await setUserBiaozhun(this.biaozhunForm).catch((err) => {
          this.$message.error('网络错误');
        })
        if (res.status === 1) {
          this.bzVisible = false;
          this.$message.success("操作成功");
          this.getJishiList();
        }else{
          this.$message.error(res.msg);
        }
      },
      handleBiaozhunClick(tab, event) {
        if (tab.paneName.indexOf('type@')>-1) {
          const type_id = parseInt(tab.paneName.split('@')[1])
          const bty = this.biaozhunTypeList.find(item => item.type_id === type_id)
          console.log(type_id, bty)
          if (bty.biaozhun_id) {
            this.biaozhunShow = this.biaozhun_list.find(item => item.biaozhun_id === bty.biaozhun_id)
          }
        }
      },
			//修改门派
			changeSect(item) {
				this.jishi_id = item.jishi_id;
				this.editSect = item.menpai_id;
				this.sectVisible = true
			},
			//紧急联系人
			contactView(item){
				var url = 'jishi/contact_list';
        let params = {
          jishi_id: item.jishi_id,
        };
        this.fd_post(url, params).then((res) => {
          if(res.status){
            this.contact_list = res.list;
            this.contactVisible = true;
          }else{
            this.$message.error(res.msg);
          }
        }).catch((err) => {
            this.$message.error('网络错误');
        });
			},
			//修改代理商
			changeAgent(item){
				this.jishi_id = item.jishi_id;
				this.editAgent = item.agent_id;
				this.agentVisible = true
			},
			//确认修改门派  
			subSectEdit() {
				var url = 'jishi/updateMenpai';
		        let params = {
		        	jishi_id: this.jishi_id,
		        	menpai_id: this.editSect,
		        };
		        this.fd_post(url, params).then((res) => {
	        		if(res.status){
	        			this.sectVisible = false;
	        			this.$message.success("操作成功");
	        			this.getJishiList();
	        		}else{
	        			this.$message.error(res.msg);
	        		}
		        }).catch((err) => {
		            this.$message.error('网络错误');
		        });
			},
			//确认修改代理商
			subAgentEdit() {
				var url = 'jishi/updateAgent';
		        let params = {
		        	jishi_id: this.jishi_id,
		        	agent_id: this.editAgent,
		        };
		        this.fd_post(url, params).then((res) => {
	        		if(res.status){
	        			this.$message.success("操作成功");
	        			this.getJishiList();
	        		}else{
	        			this.$message.error(res.msg);
	        		}
		        }).catch((err) => {
		            this.$message.error('网络错误');
		        });
			},
			//关联服务
			relationService(item){
				this.jishi_id = item.jishi_id;
				this.serviceList = [];
				this.serviceVisible = true;
				that.$nextTick(function(){
					that.$refs.xyfwTable.clearSelection();
					let service_list = [];
					that.serviceTable.forEach(row => {
						item.service_list.forEach(val=>{
							if(row.service_id === val.service_id){
								service_list.push(row);
							}
						})
					});
					if(service_list.length>0){
						service_list.forEach(row => {
				            that.$refs.xyfwTable.toggleRowSelection(row, true);
			          	});
					}
				});
			},
			getService(){
				this.getServiceList();
			},
			//页数改变
			handleSizeChangeService(newSize) {
				this.queryInfoService.pagenum = 1;
				this.queryInfoService.pagesize = newSize;
				this.getServiceList();
			},
			//页码改变
			handleCurrentChangeService(newPage) {
				this.queryInfoService.pagenum = newPage;
				this.getServiceList();
			},
			getServiceList(){
				var url = 'service/service_list';
				let params = {
					is_on_sale: 1,
					service_name: this.serviceText,
					size: this.queryInfoService.pagesize,
					page: this.queryInfoService.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.serviceTotal = res.total;
						that.serviceTable = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
      relationSubmit() {
        this.serviceVisible = false;
        let newarr = [...this.serviceList, ...this.selectdata];
				let map = new Map()
				this.serviceList = newarr.filter(item => !map.has(item.service_id) && map.set(item.service_id, 1));
				this.selectdata = [];
				this.$refs.xyfwTable.clearSelection();
				if(this.serviceList.length>0){
					var url = 'service/relation_jishi';
			        let params = {
			        	jishi_id: this.jishi_id,
			        	service_json: JSON.stringify(this.serviceList)
			        };
			        that.fd_post(url, params).then((res) => {
		        		if(res.status){
		        			that.$message.success("操作成功");
		        			that.getJishiList();
		        		}else{
		        			that.$message.error(res.msg);
		        		}
			        }).catch((err) => {
			            that.$message.error('网络错误');
			        });
				}
      },
      select(selection, row) {
        this.selectdata = selection;
      },
      selectAll(selection){
        this.selectdata = selection;
      },
      //打开停用设置
      openStop(item){
        this.stopTypeList = item.type_list
        this.stopForm.stop_user_id = item.jishi_id
        this.stopForm.stop_reason = ""
        this.stopForm.type_id = null
        this.stopForm.stop_type = null
        this.stopDialog = true
      },
      stopTimeChange(e) {
        console.log(this.stopTime)
        this.stopForm.stop_start = this.stopTime[0]
        this.stopForm.stop_end = this.stopTime[1]
      },
      async stopSetting() {
        console.log("【停止表单】",this.stopForm)
        this.$refs.stopForm.validate(async valid => {
          if (valid) {
            const res = await setUserStop(this.stopForm).catch((err) => {
              this.$message.error('网络错误');
            });
            console.log("【停止222】",res)
            if(res.code === 200) {
              this.stopDialog = false;
              this.$refs.stopForm.resetFields();
              this.$message.success('操作成功');
              this.getJishiList();
            } else {
              this.$message.error(res.msg);
            }
          }
        });
      },

		},
	};
</script>

<style lang="scss" scoped>
	@import "./Technician_manage.scss";
</style>